.cardCantidad{
    background-color: var(--blanco);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
   
    border-radius: 10px;
    padding: 1rem;
    width: 30%;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 15vh;
}
#cardCantidad2{
   width: 45%;
   
}
.CardsCantidad{
    display: flex;
    flex-wrap: wrap;
   
    gap: 2rem;
}
.CardsCantidad a{
   background-color: var(--blanco);
}
.CardsCantidad a:hover{
    background-color: var(--color1);
    color: var(--blanco);
 }
 .CardsCantidad a:hover h3{
   
    color: var(--blanco);
 }
 .CardsCantidad a:hover h2{
   
    color: var(--blanco);
 }
 .CardsCantidad a:hover .icons{
   
   color: var(--color1);
   background-color: var(--blanco);
 }
 .deFlex{
    display: flex;
    flex-direction: column;
 }
 .cardCantidad h2{
   
    font-size: 20px;
    color: var(--color1);
 }
 .cardCantidad h3{
   
   font-size: 1em;
    font-weight: 500;
    color: var(--text-color2);
 }
 .icons{
    font-size: 20px;
    color: var(--blanco);
    background-color: var(--color1);
    padding: 14px;
    border-radius: 7px;
    
}

@media (max-width: 1024px){
   .CardsCantidad{
     flex-direction: row;
     justify-content: space-around;
     width: 100%;
     gap: 1rem;
  }
  .cardCantidad{
   width: 45%;
  }
  #cardCantidad2{
   width: 46%;
  }
  .icons{
   font-size: 15px;

   padding: 10px;

   
}
.cardCantidad h2{
   
   font-size: 17px;
   
}
.cardCantidad h3{
   
   font-size: 15px;
  
}
}