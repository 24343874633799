.cards-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: 100%;
    overflow-y: scroll;
    height: 60vh;
    border-radius: 8px;
    background-color:var(--gris);
   

}
.cards-container::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}

 
.cards-container::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }
.estado-container {
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.card h3{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 16px;
}
.card span{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 13px;
}
.estado-container h2{
    color: var(--text-color2);
    font-weight: 500;
    text-align: center;
    background-color: var(--blanco);
    border-radius: 8px;
    padding: 7px;
    width: 100%;
    font-size: 1em;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);

}
.cards-row {
    display: flex; 
    gap: 20px;
    flex-direction: column;
    padding-bottom: 40px;
    
}

.card {
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color:var(--blanco);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-actions {
    display: flex;
    justify-content: space-between;
}

.card-actions{
    display: flex;

}

.card-actions button:nth-child(1) {
   background-color: var(--color1);
   width: 100%;
   color: var(--blanco);
   padding: 6px;
   font-size: 14px;
   border-radius: 8px;
}

.card-actions button:nth-child(2) {
    background-color: var(--color2);
    width: 100%;
    color: var(--blanco);
    padding: 6px;
    font-size: 14px;
    border-radius: 8px;
}
.card-actions button:nth-child(3) {
    background-color: var(--color2);
    width: 100%;
    color: var(--blanco);
    padding: 6px;
    font-size: 14px;
    border-radius: 8px;
}
.moreBtn{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 13px;
    background-color: transparent;
    border: none;
    text-align: left;
}

.deFlexLink{
    display: flex;
    gap: 10px;
    background-color: var(--gris);
    border-radius: 10px;
    align-items: center;
}
.deFlexLink a{
    padding: 10px 20px;
    color: var(--text-color2);
    font-size: .85em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  
}
.activeLin{
    color: var(--blanco)!important;
    background-color: var(--color1);
    padding: 8px;
    border-radius: 10px;
}

.deFlexContent2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    
}

@media (max-width: 900px) {
    .card-actions {
   
        gap: 10px;
    
    }
    .estado-container h2{
        width: 35vh;
    }
}
