.paddingConten{
    padding: 20px 10px 10px;
 }
 .paddingConten h4{
     color: var(--text-color2);
     padding-bottom: 5px;
 }
 .paddingConten hr{
     margin-bottom: 1rem;
 }
 
 .MiPedidoContain .modal-content-cart{
     width: 100%;
     overflow-y: scroll;
     height: 55vh!important;
     padding: 10px 20px;
     
 }
 .modal-cart .inputSearch{
     width: auto;
     margin: 0 10px ;
 }
 .MiPedidoContain .cardProductData{
     width: 100%;
 }
 .deFlexSpanPedido{
     display: flex;
     flex-wrap: wrap;
     gap: 10px;
 }
 .deFlexSpanPedido::-webkit-scrollbar{
     width: 5px;
     height: 5px;
     cursor: pointer;
     background-color: transparent;
    
 }
  
 .deFlexSpanPedido::-webkit-scrollbar-thumb {
     background-color: var(--color1); 
     border-radius: 10px;
     margin-top: 3rem;
   }
 .deFlexSpanPedido span{
     background-color: var(--gris2);
     padding: 6px 15px;
     border-radius: 2rem;
     min-width: 8rem;
     text-align: center;
     color: var(--text-color2);
     font-size: 0.9em;
 }
 
 .deFlexBtnCart{
     display: flex;
     align-items: center;
     width: 100%;
     justify-content: space-between;
     gap: 10px;
 }
 .deFlexBtnCart .btn{
     width: 100%;
     margin: 0;
     font-size: 14px;
 }
 @media (max-width: 1024px){
     .MiPedidoContain .modal-content-cart {
         height: 60vh;
     }
     .deFlexSpanPedido span{
        font-size: 13px;
        min-width: 7rem;
     }
 }