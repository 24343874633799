.navbarDashboard{
    background-color: var(--color1);
    display: flex;
    flex-direction: column;
    width: 4rem;
    min-height:  100vh;
    max-height: 100%;
    padding: 20px 0rem 0% 6px;
    overflow: hidden;
    transition: width 0.5s;
    border-radius: 0px 10px 10px 0px;
   
}
.navbarDashboard .logo {
   gap: 1.2rem;
}
.navbarDashboard:hover .logo{
    color: var(--blanco)!important;
    background-color: transparent;
 }
.navbarDashboard .logo .deColumnNav h2{
    color: var(--blanco);
}

.navbarDashboard .logo .deColumnNav strong{
    color: var(--blanco);
}
.navbarDashboard:hover {
    width: 20rem;
    transition: width 0.5s;
}
.navbarDashboard .submenu {
    display: none;
 }
.navbarDashboard:hover .submenu {
   display: flex;
}
.navbarDashboard img{
    width: 2.5rem;
    padding: 0px ;
    border-radius: 100%;
    margin-left: -10px;
   
}
.submenu{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    text-align: left!important;
    justify-content: left!important;
}
.submenu:hover #btnSubmenu{
    background-color: var(--gris)!important;
}
.content_tables {
    transition: height 0.5s, width 0.5s;
}
.navbarDashboard a{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2rem;
    color: var(--blanco);
    padding: 10px 1.1em;
    border-radius:30px 0px 0px 30px ;
    font-size: 1em;
  
}
.navbarDashboard #btnSubmenu{
    display: flex;
    width: 100%;
    justify-content: left;
    text-align: left;
    gap: 2rem;
    color: var(--blanco);
    padding: 13px 1.1em;
    border-radius:30px 0px 0px 30px ;
    font-size: 1em;
    border: none;
    background-color: transparent;
   
}
.navbarDashboard #btnSubmenu:hover{
    background-color: var(--gris);
    color: var(--color1);
}
.navbarDashboard a:hover{
    background-color: var(--gris);
    color: var(--color1);
}
.navbarDashboard a.active {
    background-color: var(--gris);
    color: var(--color1);

}
.activeLink{
    background-color:var(--gris);
    width: 100%;
    color: var(--color1)!important;
    border-radius:30px 0px 0px 30px ;
}
.resize-button {
    display: none;
}
.nav_toggle2 {
    display: none;
}
.navbarDashboard .links{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}
#submenu{
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 0;
    padding: 0;
}
#submenu{
   padding: 0;
   margin: 0;
}

.navbarDashboard #submenu:hover {
    background-color: transparent;
}

#submenu:hover {
    background-color: transparent;
}

.activeLinkBtn{
    background-color: var(--gris);
    color: var(--color1);
    border: none;
    padding: 10px;
    border-radius:30px 0px 0px 30px ;
    font-size: 0.9em;
}
.activeLinkBtn2{
    background-color: var(--gris);
    color: var(--color1);
    border: none;
    padding: 10px;
    border-radius:30px 0px 0px 30px ;
    padding-left: 2rem;
    margin-left: 2rem;
    text-align: left;
    font-size: 0.9em;
}
.desactiveLinkBtn{
    background-color: transparent;
    color: var(--blanco);
    border: none;
    padding: 10px;
    border-radius:30px 0px 0px 30px ;
    margin-top: 10px;
    text-align: left;
    padding-left: 2rem;
    margin-left: 2rem;
    font-size: 0.9em;
}
.desactiveLinkBtn:hover{
    background-color: var(--gris);
    color: var(--color1);
}

#btnSubmenu #btnLog{
    margin-right: 7rem!important;
}
@media (max-width: 1024px) {
   
   
   
    .navbarDashboard{
        width: 100%;
        min-height:  4rem;
        max-height: 4rem;
        padding: 20px 0rem 0% 6px;
        transition: max-height 0.5s;
        padding: 0px ;
        border-radius: 0;
        
        
}
    /* .navbarDashboard:hover {
        width: 100%;
        max-height: 100%;
        transition: max-height 0.5s;
        
} */
.navbarDashboard:hover {
    width: 100%;
}
.navbarDashboard a{
   
    margin-top: 0;
    margin-bottom: 1rem;
    
  
}

.navbarDashboard.expanded {
    max-height: 100%; 
    width: 100%;
    transition: max-height 0.5s;
}

.resize-button {
    display: flex;
    background-color: transparent;
    color: var(--blanco);
    border: none;
}
#logo{
    display: flex;
    justify-content: space-between;
}
#logo button{
    background-color: transparent;
    border: none;

}

.nav_toggle2{
    display: flex !important;
    flex-direction: column;       
    cursor: pointer;
    justify-content: right;
    text-align: right;
   

}
.nav_toggle2 span{
    width: 25px;
    height: 3px;
    background: var(--blanco);
    margin-bottom: 5px;
    border-radius: 20px;
    transform-origin: 3px 0px;
    transition: all 0.2s linear;
    display: flex;
    justify-content: right;
    text-align: right;
  
}
.nav_toggle2 span:nth-child(1) {
    width: 15px;
    margin-left: 25%;
}

.nav_toggle2 span:nth-child(2) {
    width: 25px; 
    margin-left: -2%;
}

.nav_toggle2 span:nth-child(3) {
    width: 35px; 
    margin-left: -30%;
}

}

