.FooterContain{
    padding: 50px 10%;
    border-radius: 20px 20px 0 0;
    background-color: var(--color1);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3rem;
}

.FooterContain a img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: contain;
}

.footerText{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.footerText h3{
    color: var(--blanco);
    font-size: 1em;
    font-family: 'Poppins';
}
.footerText h2{
    color: var(--blanco);
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 1.2em;
}
.footerText a{
    font-size: 1em;
    color: var(--blanco);
}
.footerText .socials{
    display: flex;
    justify-content: center!important;
    gap: 2rem;
    
}
.btnAnch{
    background-color: var(--blanco);
    color: var(--color1)!important;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 8rem;
    text-align: center;
    justify-content: center;
}
.footerText{
    text-align: center;
}
.footerText .socials{
   
    justify-content: space-between;
}
@media (max-width: 700px){
    .FooterContain{
    margin: 0;
    padding: 50px 4%;
    border-radius: 20px 20px 0 0;
    flex-direction: column;
    align-items: center;

}
.footerText .socials{
   
    justify-content:center;
}
}