.deleteFav{

    cursor: pointer;
    color: red;
    font-weight: 500;
    background-color: transparent;
    border: none;
    font-size: 17px;
}
.cartIcon strong{
    color: var(--text-color2);
    font-weight: 500;
}
.cartIcon {
    display: flex;
    gap: 10px;
    align-items: center;
}
@media (max-width: 900px) {
    .cartIcon strong{
        display: none;
    }
}