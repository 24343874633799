.cardsProductData{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1rem;
}
#cardsProductData{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1rem;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    border: 1px solid #d1cccc;
}

.cardProductData{
    min-width: 18rem;
    width: 47%;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 10px;
    background-color: var(--blanco);
}
.cardProductData img{
    width: 5rem;
    height: 5rem;
    border-radius: 10px;
    object-fit: cover;
    background-image: var(--gris);
}
.cardProductDataText{
    display: flex;
    flex-direction: column;
    gap: 3px;
   
}
.cardProductDataText h3{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 1em;
}
.cardProductDataText h5{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 10px;
}
.cardProductDataText span{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 0.9em;
}
.cardProductDataText strong{
    font-weight: 500;
    color: var(--color1);
    font-size: 0.8em;
}
.cardProductDataText strong span{
    padding-left: 2rem;
    color: var(--color1);
    font-size: 15px;
}

.pendiente {
    background-color: yellow!important;
  }
  
  .cancelado {
    background-color: red!important;;
  }
  
  .entregado {
    background-color: blue!important;
  }
  
  .pagado {
    background-color: green!important;
  }
  



#modal-content fieldset{
        width: 32%;
}
#modal-content{
    width: 150vh;
    height: 90vh;
}
.deFlexBtnsFilset{
    display: flex;
    gap: 10px;
}
.deFlexBtnsFilset{
    display: flex;
    gap: 10px;
    padding: 7px;
}
.Noactivo{
    background-color: var(--gris2);
    color: var(--text-color2);
    padding: 8px 15px;
    border: none;
    border-radius: 10px;
}

.activo{
    background-color: var(--color1);
    color: var(--blanco);
    padding: 8px 15px;
    border: none;
    border-radius: 10px;
}
.recibirAbsolute{
    z-index: 2;
    border-radius: 4px;
    display: flex;
    gap: 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: -1.4rem;
    font-size: 0.8em;
   
}
.recibirAbsolute span{
   
    background-color: var(--color1);
    padding: 7px 15px;
    color: var(--blanco);
    border-radius: 4px;
    font-size: 0.9em;
}
#fieldsetAuto{
    width: 15%!important;
}
@media (max-width: 500px) {
    .recibirAbsolute{
        margin-top: 0;
       
    }
    .cardProductData{
        width: 100%;
    }
    #modal-content fieldset{
        width: 100%;
}
.deFlexBtnsFilset{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 7px;
}
}