.cardsMetodos{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
}


.cardsMetodos .card{
    border-radius: 8px;
    padding: 15px;
    width: 40vh;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color:var(--blanco);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
}

.cardsMetodos .card span{
    white-space: pre-line;
    font-size: 0.8em;
    color: var(--text-color2);
}

@media (max-width: 900px) {
    
.cardsMetodos .card{
    
    width: 100%;
   
}

}